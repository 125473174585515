module.exports = [
  {"name": "Cegonha", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/cegonha/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/cegonha/content.png"], "value":1, "colors": ['#00EAE3'], "selected": 0},
  {"name": "Chafolia", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/chafolia/bg.jpg"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/chafolia/content.jpg"], "value":22, "colors": ['#01B6BC'], "selected": 0},
  {"name": "Safari", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/safari/bg.jpg"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/safari/content.png"], "value":21, "colors": ['#50B45A'], "selected": 0},
  {"name": "Ursinho azul", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/ursinho-masculino/bg.jpg"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/ursinho-masculino/content.jpg"], "value":19, "colors": ['#76AEDF'], "selected": 0},
  {"name": "Ursinho rosa", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/ursinho-feminino/bg.jpg"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/ursinho-feminino/content.jpg"], "value":20, "colors": ['#F79DB6'], "selected": 0},
  
  {"name": "Jardim", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/jardim/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/jardim/content.png"], "value":11, "colors": ['#FAC4A0'], "selected": 0},
  {"name": "Chuva de amor", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/chuva-de-amor-feminino/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/chuva-de-amor-feminino/content.png"], "value":4, "colors": ['#E4B19E'], "selected": 0},
  {"name": "Chuva de amor", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/chuva-de-amor-masculino/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/chuva-de-amor-masculino/content.png"], "value":5, "colors": ['#76C5C1'], "selected": 0},
  {"name": "Balões", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/baloes-feminino/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/baloes-feminino/content.png"], "value":2, "colors": ['#B17B7B'], "selected": 0},
  {"name": "Balões", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/baloes-masculino/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/baloes-masculino/content.png"], "value":3, "colors": ['#86B4CE'], "selected": 0},
  {"name": "Coroa", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/coroa/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/coroa/content.png"], "value":6, "colors": ['#CD9B80'], "selected": 0},
  {"name": "Fada", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/fada/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/fada/content.png"], "value":7, "colors": ['#CB7F7F'], "selected": 0},
  {"name": "Aviação", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/aviacao/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/aviacao/content.png"], "value":9, "colors": ['#CCDEE8'], "selected": 0},
  {"name": "Flamingo", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/flamingo/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/flamingo/content.png"], "value":10, "colors": ['#01CACA'], "selected": 0},
  {"name": "Náutico", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/nautico/bg.webp"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/nautico/content.png"], "value":8, "colors": ['#6F9EB8'], "selected": 0},
  {"name": "Charraia", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/charraia/bg.jpg"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/charraia/content.png"], "value":17, "colors": ['#00EAE3'], "selected": 0},
  {"name": "Halloween", "url": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/halloween/bg.jpg"], "preview": ["https://"+process.env.VUE_APP_S3_BUCKET+"/site/themes/invitations/new/halloween/content.png"], "value":18, "colors": ['#00EAE3'], "selected": 0},
]
