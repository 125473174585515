<template lang="pug" src="./Name.pug">
</template>
<script>
  import Preview from '../Preview.vue'

  import * as types from '@/store/mutation-types'
  import { store } from '@/store'
  import { mapGetters } from 'vuex'


  export default {
    components: {
      Preview,
    },
    data() {
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        showPreview: false,
        showPreviewBtn: true,
        valid: true,
        name: '',
        eventTypeQuestion: 'Qual é o nome?',
        eventTypePlaceHolder: "Escreva o nome do bebê",
        rules: {
          required: value => !!value || 'Não se esqueça do nome'
        },
        maxlength: "20"
      }
    },
    computed: {
      ...mapGetters(['eventType'])
    },
    methods: {

      setBabyName() {
        store.commit(types.BABY_NAME, this.name)
      },
      preview(){
        this.showPreview = true
      },
      next() {
        if(this.$refs.form.validate()){
          this.$router.push({name: 'date'})
        }
      }
    },
    created() {
      if(this.eventType == 'Chá Revelação'){
        this.eventTypeQuestion = "Quais são os possíveis nomes?"
        this.eventTypePlaceHolder = "Escreva os possíveis nomes"
        this.maxlength = "30"
      }
    }
  }
</script>
