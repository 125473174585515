<template lang="pug">
  v-container(fluid class="overLayer")

    Preview(class="layerHidden")

    v-row(justify="center" class="mt-8")
      v-col(cols="12" class="text-center")
        div(style="width: 120px; margin: auto;")
          v-progress-linear(color="buttons" indeterminate rounded height="6" max-width="30")
        p(class="buttons--text body-2 mt-1") Preparando seu convite com carinho

    v-row(align="center" justify="center" class="mt-14")
      v-col(cols="11" sm="11" md="6" lg="4" xl="4" class="text-center")
        v-img(:class="show1" contain :src="`${imgPath}/site/invitations-site/cta-01.webp`" transition="scale-transition" style="margin: auto;")
        v-img(:class="show2" contain :src="`${imgPath}/site/invitations-site/cta-02.webp`" transition="scale-transition" style="margin: auto;")

        v-img(:class="show3" contain :src="`${imgPath}/site/invitations-site/img-presente-lp.png`" transition="scale-transition" max-width="140" style="margin: auto;" class="mb-6 mt-4")
        h2(:class="show3" class="buttons--text") Crie sua lista <span class="primary--text">grátis</span>
        p(:class="show3" class="primary--text body-1") Não custa nada criar sua lista com a iFraldas, é muito mais praticidade para você e seus convidados

    v-footer(padless elevation="0" bottom color="white")
      v-col(cols="12")
        v-img(alt="Logo" max-width="110px" contain :src="`${imgPath}/site/imgs/logo-ifraldas.png`" transition="scale-transition" @click="goTo('home')" style="margin: auto;") <br />
        p(class="caption accent--text font-weight-bold text-center") Seu chá de bebê começa aqui
</template>
<script>

  import { mapActions, mapGetters } from 'vuex'
  import { store } from '@/store'
  import * as types from '@/store/mutation-types'
  import html2canvas from 'html2canvas'
  import Preview from './Preview.vue'

  export default {
    components: {
      Preview
    },
    data: () => ({
      imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
      hiddenPreview: false,
      slugName: 'default',
      show1: 'show',
      show2: 'hidden',
      show3: 'hidden'
    }),
    computed: {
      ...mapGetters(['slug', 'carouselFinished', 'uploadFinished'])
    },
    methods: {
      ...mapActions([ 'getUploadUrl', 'newUploadFile' ]),

      checkAndGo() {
        // finalizou o carrocel e os uploads
        if(this.carouselFinished && this.uploadFinished){ this.$router.push( { name: 'finish' } ) }
      },

      imageCarousel(){

        let i = 2
        let interval = setInterval(() => {
          this.show1 = 'hidden'
          this.show2 = 'hidden'
          this.show3 = 'hidden'

          this['show'+i] = 'show'

          if(i > 3) {
            store.commit(types.CAROUSEL_FINISHED, true)
            clearInterval(interval)
          }
          i++

        }, 8000)

      },

      async exportInvitation(){

        document.getElementById("container").classList.remove("compact")

        this.showLoading = true

        await this.toCanvas('invitation-container', 0.6, '_thumb.png', 'invitationslp')
        await this.toCanvas('invitation-container', 1.6, '.png', 'invitationslp')

        store.commit(types.UPLOAD_FINISHED, true)

      },

      async toCanvas(elementId, scale, filePrefix, folder) {
        let self = this

        // é preciso rodar duas vezes para carregar a imagem de background
        let canvas = await html2canvas(document.getElementById(elementId), {
          scale: scale,
          allowTaint: true,
          useCORS: true
        })

        canvas = await html2canvas(document.getElementById(elementId), {
          scale: scale,
          allowTaint: true,
          useCORS: true
        })

        return new Promise((resolve) => {

          
          canvas.toBlob(async function(blob) {
          
          const filename = self.slugName+filePrefix
          let imgFile = new File([blob], "iFraldas.jpg")

          const url = await self.getUploadUrl({
            op: 'PUT',
            cdn: process.env.VUE_APP_S3_BUCKET,
            bucket: folder,
            filename: filename
          })

          await self.newUploadFile({
            op: 'PUT',
            url: url,
            formData: imgFile
          })

            resolve(true)

          })

        })
      }
    },
    mounted() {
      this.slugName = this.slug
      this.imageCarousel()
      this.exportInvitation()
    },
    watch: {
      slug(newVal){
        this.slugName = newVal
      },
      carouselFinished(val) {
        if(val){ this.checkAndGo() }
      },
      uploadFinished(val) {
        if(val){ this.checkAndGo() }
      }
    }

  }
</script>
<style scoped>
  .icons {
    height: .75em !important;
  }

  .show {
    display: block;
  }

  .hidden {
    display: none;
  }
  .layerHidden{
    position: absolute;
    z-index: -1 !important;
  }
  .overLayer {
    z-index: 2 !important;
  }
</style>
