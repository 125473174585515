<template lang="pug">
  v-layout(fill-height align-center id="container" :class="{'compact': $vuetify.breakpoint.smAndDown}")
    div(id="alignment-container")
      div(id="invitation-container")
        v-img(:src="url" crossorigin="anonymous")
        div(v-if="eventType" id="event-type" class="event-type") {{ eventType }}
        div(v-if="babyName" id="baby-name" class="baby-name") {{ babyName }}
        div(v-if="eventDate" id="event-date" class="event-date") {{ eventDate }}
        div(v-if="extraInfo" id="extra-info" class="extra-info") {{ extraInfo }}
</template>
<script>
  import { mapGetters } from 'vuex'
  import router from '@/router'
  import findIndex from 'lodash/findIndex'
  export default {
    name: 'Preview',

    data() {
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        themes:[
          {name: "Cegonha", url: [require("@/assets/invitations/news/cegonha/bg.webp")], value:1, colors: ['#00EAE3'], selected: 0},
          {name: "Jardim", url: [require("@/assets/invitations/news/jardim/bg.webp")], value:11, colors: ['#FAC4A0'], selected: 0},
          {name: "Chuva de amor", url: [require("@/assets/invitations/news/chuva-de-amor-feminino/bg.webp")], value:4, colors: ['#E4B19E'], selected: 0},
          {name: "Chuva de amor", url: [require("@/assets/invitations/news/chuva-de-amor-masculino/bg.webp")], value:5, colors: ['#76C5C1'], selected: 0},
          {name: "Balões", url: [require("@/assets/invitations/news/baloes-feminino/bg.webp")], value:2, colors: ['#B17B7B'], selected: 0},
          {name: "Balões", url: [require("@/assets/invitations/news/baloes-masculino/bg.webp")], value:3, colors: ['#86B4CE'], selected: 0},
          {name: "Coroa", url: [require("@/assets/invitations/news/coroa/bg.webp")], value:6, colors: ['#CD9B80'], selected: 0},
          {name: "Fada", url: [require("@/assets/invitations/news/fada/bg.webp")], value:7, colors: ['#CB7F7F'], selected: 0},
          {name: "Aviação", url: [require("@/assets/invitations/news/aviacao/bg.webp")], value:9, colors: ['#CCDEE8'], selected: 0},
          {name: "Flamingo", url: [require("@/assets/invitations/news/flamingo/bg.webp")], value:10, colors: ['#01CACA'], selected: 0},
          {name: "Náutico", url: [require("@/assets/invitations/news/nautico/bg.webp")], value:8, colors: ['#6F9EB8'], selected: 0},
          {name: "Charraia", url: [require("@/assets/invitations/news/charraia/bg.jpg")], value:17, colors: ['#6F9EB8'], selected: 0},
          {name: "Halloween", url: [require("@/assets/invitations/news/halloween/bg.png")], value:18, colors: ['#6F9EB8'], selected: 0},
          {name: "Ursinho", url: [require("@/assets/invitations/news/ursinho-masculino/bg.jpg")], value:19, colors: ['#6F9EB8'], selected: 0},
          {name: "Ursinho", url: [require("@/assets/invitations/news/ursinho-feminino/bg.jpg")], value:20, colors: ['#6F9EB8'], selected: 0},
          {name: "Safari", url: [require("@/assets/invitations/news/safari/bg.jpg")], value:21, colors: ['#50B45A'], selected: 0},
          {name: "Chafolia", url: [require("@/assets/invitations/news/chafolia/bg.jpg")], value:22, colors: ['#01B6BC'], selected: 0},
          

          {name: "Basico", url: [require("@/assets/invitations/news/cha-revelacao-basico/bg.webp")], value:12, colors: ['#DBEBEB'], selected: 0},
          {name: "Passarinho", url: [require("@/assets/invitations/news/cha-revelacao-passarinho/bg.webp")], value:13, colors: ['#9E937F'], selected: 0},
          {name: "Surpresa", url: [require("@/assets/invitations/news/cha-revelacao-surpresa/bg.webp")], value:14, colors: ['#FCF9EA'], selected: 0},
          {name: "Balanço", url: [require("@/assets/invitations/news/cha-revelacao-balanco/bg.webp")], value:15, colors: ['#9C8A98'], selected: 0},
          {name: "Natureza", url: [require("@/assets/invitations/news/cha-revelacao-natureza/bg.webp")], value:16, colors: ['#E3F2DB'], selected: 0}

        ],
        url: '',
        localPath: ''
      }
    },
    computed: {
      ...mapGetters(['babyName', 'eventDate', 'eventType', 'eventTime', 'extraInfo']),
      chosenTheme() {
        return this.$store.state.themes.chosenTheme
      }
    },

    methods: {
      setStyle_old(){

        let bodyElement = document.getElementById("invitation-container")
        bodyElement.classList.remove(...bodyElement.classList) // remove todas as classes antes

        const themeVal = parseInt(this.chosenTheme.split(',')[0])

        switch (themeVal) {
          case 1: {
            require('../assets/css/cegonha/invitation.scss')
            bodyElement.classList.add("cegonha")
          }
            break

          case 2: {
            require('../assets/css/baloes/invitation.scss')
            require('../assets/css/baloes/feminine-colors.scss')
            bodyElement.classList.add("baloes")
          }
            break

          case 3: {
            require('../assets/css/baloes/invitation.scss')
            require('../assets/css/baloes/masculine-colors.scss')
            bodyElement.classList.add("baloes")
          }
            break

          case 4: {
            require('../assets/css/chuva-de-amor/invitation.scss')
            require('../assets/css/chuva-de-amor/feminine-colors.scss')
            bodyElement.classList.add("chuva-de-amor")
          }
            break

          case 5: {
            require('../assets/css/chuva-de-amor/invitation.scss')
            require('../assets/css/chuva-de-amor/masculine-colors.scss')
            bodyElement.classList.add("chuva-de-amor")
          }
            break

          case 6: {
            require('../assets/css/coroa/invitation.scss')
            bodyElement.classList.add("coroa")
          }
            break

          case 7: {
            require('../assets/css/fada/invitation.scss')
            bodyElement.classList.add("fada")
          }
            break

          case 8: {
            require('../assets/css/nautico/invitation.scss')
            bodyElement.classList.add("nautico")
          }
            break

          case 9: {
            require('../assets/css/aviacao/invitation.scss')
            bodyElement.classList.add("aviacao")
          }
            break

          case 10: {
            require('../assets/css/flamingo/invitation.scss')
            bodyElement.classList.add("flamingo")
          }
            break

          case 11: {
            require('../assets/css/jardim/invitation.scss')
            bodyElement.classList.add("jardim")
          }
            break

          case 17: {
            require('../assets/css/charraia/invitation.scss')
            bodyElement.classList.add("charraia")
          }
            break
          case 18: {
            require('../assets/css/halloween/invitation.scss')
            bodyElement.classList.add("halloween")
          }
            break            

          // -------------- REVELAÇÃO
          case 12: {
            require('../assets/css/cha-revelacao-basico/invitation.scss')
            bodyElement.classList.add("cha-revelacao-basico")
          }
            break

          case 13: {
            require('../assets/css/cha-revelacao-passarinho/invitation.scss')
            bodyElement.classList.add("cha-revelacao-passarinho")
          }
            break

          case 14: {
            require('../assets/css/cha-revelacao-surpresa/invitation.scss')
            bodyElement.classList.add("cha-revelacao-surpresa")
          }
            break

          case 15: {
            require('../assets/css/cha-revelacao-balanco/invitation.scss')
            bodyElement.classList.add("cha-revelacao-balanco")
          }
            break
          case 16: {
            require('../assets/css/cha-revelacao-natureza/invitation.scss')
            bodyElement.classList.add("cha-revelacao-natureza")
          }
            break

          default: {
            break
          }

        }
      },
      setStyle() {
        const themeMapping = {
          1: { main: 'cegonha', colors: null },
          2: { main: 'baloes', colors: 'feminine-colors' },
          3: { main: 'baloes', colors: 'masculine-colors' },
          4: { main: 'chuva-de-amor', colors: 'feminine-colors' },
          5: { main: 'chuva-de-amor', colors: 'masculine-colors' },
          6: { main: 'coroa', colors: null },
          7: { main: 'fada', colors: null },
          8: { main: 'nautico', colors: null },
          9: { main: 'aviacao', colors: null },
          10: { main: 'flamingo', colors: null },
          11: { main: 'jardim', colors: null },
          17: { main: 'charraia', colors: null },
          18: { main: 'halloween', colors: null },
          19: { main: 'ursinho', colors: 'masculine-colors' },
          20: { main: 'ursinho', colors: 'feminine-colors' },
          21: { main: 'safari', colors: null },
          22: { main: 'chafolia', colors: null },
          // Revelação
          12: { main: 'cha-revelacao-basico', colors: null },
          13: { main: 'cha-revelacao-passarinho', colors: null },
          14: { main: 'cha-revelacao-surpresa', colors: null },
          15: { main: 'cha-revelacao-balanco', colors: null },
          16: { main: 'cha-revelacao-natureza', colors: null },
        };

        const themeInfo = themeMapping[parseInt(this.chosenTheme.split(',')[0])];

        if (themeInfo) {
          const bodyElement = document.getElementById("invitation-container");
          // Remove todas as classes de estilo
          bodyElement.classList.value = ''

          // Adiciona a classe das cores (se houver)
          if (themeInfo.colors) { 
            console.log('oi', themeInfo.colors)
            import(`../assets/css/${themeInfo.main}/${themeInfo.colors}.scss`) }

          // Carrega o arquivo de estilo principal
          import(`../assets/css/${themeInfo.main}/invitation.scss`)
            .then(() => {
              console.log('themeInfo.main', themeInfo.main)
              // Adiciona a classe do tema principal
              bodyElement.classList.add(themeInfo.main)
            })
            .catch((error) => {
              console.error(error)
            })
            
        }
      }

    },
    mounted() {

      if(this.chosenTheme){
        const themeVal = parseInt(this.chosenTheme.split(',')[0])
        this.url = this.themes[ findIndex( this.themes, { value: themeVal } ) ].url[0]
        this.setStyle()
      }else{
        router.push({ name: 'landing' })
      }
    }
  }
</script>
<style scoped>

  .compact {
    height: 0px !important;
    transform: scale(0.63);
    transform-origin: left;
  }
  #alignment-container {
    position: relative;
    margin: auto;
    text-align: center;
    width: 432px;
    height: 540px;
  }

  #invitation-container{
    width: 432px;
    height: 540px;
    margin: 0px;
  }

</style>
