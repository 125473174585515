<template lang="pug">
  v-container(fluid)
    v-row
      v-col(class="text-center")
        h2(class="buttons--text") Tema
        p(class="subtitle-1 accent--text") Escolha qual o tema do seu convite
    v-row(justify="center")
      v-col(cols="12")
        v-fade-transition(mode="out-in")
          v-row(class="pt-4 pb-2" justify="center")
            v-col(cols="6" sm="6" md="2" lg="2" xl="2" v-for="(item, i) in themesArray" :key="i")

              v-card(elevation="1" class="center-card")
                v-responsive(:aspect-ratio="864/1080")
                  v-img(:src="item.preview[item.selected]" :lazy-src="`${imgPath}/site/imgs/shortLogo.png`" height="100%" position="top center" @click="chooseTheme(item.value, item.selected)" style="cursor: pointer;")
                    template(v-slot:placeholder)
                      v-row(class="fill-height ma-0" align="center" justify="center")
                        v-progress-circular(indeterminate color="primary")

    v-footer(padless elevation="0" bottom class="mt-4" color="white")
      v-col(cols="12")
        v-img(alt="Logo" max-width="110px" contain :src="`${imgPath}/site/imgs/logo-ifraldas.png`" transition="scale-transition" @click="goTo('home')" style="margin: auto;") <br />
        p(class="caption accent--text font-weight-bold text-center") Seu chá de bebê começa aqui
</template>
<script>
  import { mapActions, mapGetters } from 'vuex'
  import router from '@/router'

  export default{
    name: "ChooseTheme",

    data() {
      return {
        tab: null,
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        themes: [],
      }
    },
    computed: {
      ...mapGetters(['eventType']),

      themesBabyShower() {
        return require('./common/babyShower.js')
      },
      themesGenderReveal() {
        return require('./common/genderReveal.js')
      },

      chosenTheme() {
        return this.$store.state.themes.chosenTheme
      },
      themesArray: {
        get: function() { return this.themes },
        set: function(newVal) {
          this.themes = newVal
          return this.themes
        }
      },
      colorInd: {
        get: function() { return this.colorIndex },
        set: function(newVal) {
          this.colorIndex = newVal
          return this.colorIndex
        }
      }
    },
    methods: {
      ...mapActions(['setTheme']),

      changeColor(i, color) {
        this.colorIndex = this.themesArray[i].colors.indexOf(color)
        this.themesArray[i].selected = this.colorIndex
      },

      async chooseTheme(val, chosenColorIndex){
        let theme = val+','+chosenColorIndex

        await this.setTheme({ value: theme })
        router.push({ name: 'name' })
      },

      goTo(address){
        let href = ''
        switch (address)  {
          case 'instagram': href = 'https://www.instagram.com/ifraldas'
            break
          case 'facebook': href = 'https://www.facebook.com/ifraldas'
            break
          default: href = 'https://www.ifraldas.com.br?utm_source=lp+convites&utm_medium=landing+page&utm_campaign=convites&utm_id=teste&utm_term=ifraldas'
            break
        }
        window.open(href, '_blank');
      },

    },
    created() {
      if(this.eventType == 'Chá Revelação'){ this.themesArray = this.themesGenderReveal
      }else{ this.themesArray = this.themesBabyShower }
    }
  }
</script>
<style>
  .color-btn{
    padding: 0 !important;
    min-width: 24px !important;
    width: 24px !important;
    height: 24px !important;
    border-radius: 12px;
  }
  .center-card{
    margin: auto;
  }
</style>
