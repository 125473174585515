<template lang="pug" src="./Date.pug">
</template>
<script>
  import Preview from '../Preview.vue'

  import * as types from '@/store/mutation-types'
  import { store } from '@/store'

  export default {
    components: {
      Preview,
    },
    data() {
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        showPreview: false,
        showPreviewBtn: true,
        valid: true,

        dateString: '',

        rules: {
          required: value => !!value || 'Por favor, preencha todos os dados'
        }
      }
    },

    methods: {

      setDate() {
        store.commit(types.EVENT_DATE, this.dateString)
      },

      preview(){
        this.showPreview = true
      },

      writeExampleDate(){
        let event = new Date()
        const options = { year: 'numeric' }
        return 'ex: Dia 10 de maio de '+event.toLocaleDateString('pt-BR', options)+' às 20h'
      },

      next(){
        if(this.$refs.form.validate()){
          this.$router.push({name: 'address'})
        }
      }
    }
  }
</script>
