<template lang="pug" src="./Address.pug">
</template>
<script>
  import Preview from '../Preview.vue'

  import * as types from '@/store/mutation-types'
  import { store } from '@/store'
  import { mapGetters } from 'vuex'

  import VDigitalTimePicker from 'v-digital-time-picker'
  import Vue from 'vue'
  Vue.use(VDigitalTimePicker)

  export default {
    components: {
      Preview,
    },
    data() {
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        showPreview: false,
        showPreviewBtn: true,
        valid: true,

        extraInfo: '',

        rules: {
          required: value => !!value || 'Por favor, preencha todos os dados'
        }
      }
    },

    computed: {
      ...mapGetters(['eventType'])
    },

    methods: {

      setExtraInfo() {
        store.commit(types.EXTRA_INFO, this.extraInfo)
      },

      setMessage() {
        let msgObj = {}
        switch (this.eventType) {

          case 'Chá Rifa':
            msgObj = {
              msg: 'Aqui é o lugar para você colocar o endereço, alguma mensagem para os convidados ou o prêmio do chá rifa por exemplo.',
              placeholder: 'Endereço, prêmio do chá rifa etc...'
            }


            break

          default:
            msgObj = {
              msg: 'Aqui é o lugar para você colocar o endereço ou alguma mensagem para os convidados por exemplo.',
              placeholder: 'Endereço, mensagem para convidados etc...'
            }
            break
        }
        return msgObj
      },

      preview(){
        this.showPreview = true
      },

      next(){
        if(this.$refs.form.validate()){
          this.$router.push({name: 'user-info'})
        }
      }
    }
  }
</script>
