<template lang="pug" src="./UserInfo.pug">
</template>
<script>
  import Preview from '../Preview.vue'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    components: {
      Preview,
    },
    data() {
      return{
        imgPath: "https://"+process.env.VUE_APP_S3_BUCKET,
        showPreview: false,
        showPreviewBtn: true,
        loading: false,
        valid: true,
        email: '',
        emailRules: [
          v => !!v || 'Precisamos do seu e-mail para enviar o convite',
          v => /.+@.+\..+/.test(v) || 'Digite um e-mail válido',
        ],
        week: null,
        weeks:['já nasceu!','não estou grávida',3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,
        22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42],
        picker: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        formattedPicker: '',
        menu1: false,
        timeValue: '',
        minDate: new Date().toISOString().slice(0,10),
        rules: {
          required: value => !!value || 'Campo obrigatório'
        }
      }
    },
    computed: {
      ...mapGetters(['babyName', 'eventDate', 'eventType', 'extraInfo'])
    },
    methods: {
      ...mapActions(['postLead']),

      async getClientIdFromGtag() {
        return new Promise((resolve) => {
          this.$gtag.query("get", "G-Q3FKJ1Q86W", "client_id", clientID => {
            resolve(clientID)
          })
        })
      },
      getCookie(name) {
        var cookies = document.cookie.split(";"), toReturn;

        for (var i = 0; i < cookies.length; i++) {
          var cookie = cookies[i].trim();
          if (cookie.indexOf(name + "=") === 0) {
            toReturn = cookie.substring((name + "=").length, cookie.length);
          }
        }
        return toReturn
      },

      preview(){
        this.showPreview = true
      },

      formatAndSaveDate () {
         const [year, month, day] = this.picker.split('-')
         this.formattedPicker = `${day}/${month}/${year}`
       },

      validateJSON(jsonString) {
        try {
          JSON.parse(jsonString)
          return true
        } catch (error) {
          return false
        }
      },

      async next(){
        
        if(this.$refs.form.validate()){
          
          this.loading = true

          if(this.week == 'já nasceu!'){ this.week = 0 }
          if(this.week == 'não estou grávida'){ this.week = -1 }

          let eType = ''
          switch(this.eventType) {
            case 'Chá Rifa':
              eType = 'chaRifa'
              break
            case 'Chá Revelação':
              eType = 'chaRevelacao'
              break
            default:
              eType = 'chaFraldas'
              break
          }

          let payload = {
            email: this.email,
            gestationWeeks: Number(this.week),
            babyName: this.babyName,
            eventDate: this.eventDate,
            extraInfo: this.extraInfo,
            eventType: eType,
            eventAlias: this.eventType,
            userAgent: navigator.userAgent
          }

          var utmCookie = this.getCookie('utmCookie')
          var fbc = this.getCookie('_fbc')
          var fbp = this.getCookie('_fbp')

          let rdtrk = decodeURIComponent(this.getCookie('rdtrk'))

          var gaid = await this.getClientIdFromGtag()

          payload.utm = {}
          payload.utm.agent = window.navigator.userAgent
          if(this.validateJSON(utmCookie)){ // tem Cookie do Google Ads
            payload.utm = JSON.parse(utmCookie)
          }
          if(fbc != undefined){ // tem Cookie do Facebook
            payload.utm.fbc = fbc
          }
          if(fbp != undefined){ // tem Cookie do Facebook
            payload.utm.fbp = fbp
          }
          if(gaid != undefined){ // tem Cookie do Analytics
            payload.utm.gaid = gaid
          }
          if(this.validateJSON(rdtrk)){
            rdtrk = JSON.parse(rdtrk)
            payload.utm.rdtrk = rdtrk.id
          }

          await this.postLead(payload)
          this.loading = false
          // registra conversão no google ADS
          // window.gtag_report_conversion()

          this.$router.push({ name: 'known-us' })
        }

      }
    }
  }
</script>